import {Box, Theme, useMediaQuery} from "@material-ui/core"
import Anime from "react-anime"
import GbLogo from "./GbLogo"
import * as React from "react"
import { useTheme } from "@material-ui/core/styles"
import GbLogoWhite from "./GbLogo-white";

export const FloatingGb = () => {
  const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="50%"
      marginLeft="auto"
      marginRight="auto"
    >
      <Anime
        easing="easeInOutSine"
        duration={8000}
        direction="alternate"
        loop={true}
        translateY={isSmScreen ? "40px" : ["0", "-45px"]}
      >
        {theme.palette.type === "dark" ? <GbLogoWhite/> : <GbLogo />}
      </Anime>
    </Box>
  )
}
