import * as React from "react"
import { Box } from "@material-ui/core"

const GbLogo = () => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 99.325668 135.23795"
        width="100%"
        height="400px"
      >
        <g transform="translate(-27.070751,-44.85016)">
          <path
            stroke="#101010"
            fill="transparent"
            d="M 93.77608,52.566878 C 1.2190751,16.351632 26.883653,147.77638 90.295344,107.02584 76.607192,116.24748 64.0325,138.06673 84.661766,161.6539"
          />
          <path
            stroke="#101010"
            fill="transparent"
            d="M 28.403251,112.71972 C 60.809259,70.042891 91.003273,78.463406 102.59236,84.829286 128.76034,99.203344 108.89668,132.66785 56.40285,136.7777 214.73562,103.60254 50.933006,247.00119 42.580588,135.58272"
          />
        </g>
      </svg>
    </Box>
  )
}

export default GbLogo
