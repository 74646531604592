import * as React from "react"
import Seo from "../components/Seo"
import GbLogo from "../components/GbLogo"
import { Typography, Box, Grid, useMediaQuery, Theme } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Anime from "react-anime"
import Typewriter from "typewriter-effect"
import { FloatingGb } from "../components/FloatingGb"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles({
  baller: {
    fontFamily: "Overlock SC",
  },
})

const IndexPage = () => {
  const classes = useStyles()
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  )
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  )

  const data = useStaticQuery(graphql`
    query SiteSubtitleQuery {
      site {
        siteMetadata {
          subtitle
          roles
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Home" />
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        marginTop="40px"
        marginLeft="20px"
        marginRight="20px"
      >
        <Box margin="auto" width="100%">
          <Grid container>
            <Grid item lg={1} xl={2} />
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <FloatingGb />
            </Grid>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="left"
                height="100%"
                mt={isSmScreen ? "40px" : "0px"}
              >
                <Box>
                  <Typography>
                      {data.site.siteMetadata.subtitle}
                    <br />
                    <br />I am a Software
                  </Typography>
                  <Typography variant={isMdScreen ? "h5" : "h3"}>
                    <Typewriter
                      onInit={() => {}}
                      options={{
                        strings: data.site.siteMetadata.roles,
                        autoStart: true,
                        loop: true,
                        cursor: "▐",
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={1} xl={2} />
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default IndexPage
